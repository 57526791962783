import './styles.css';
import React from 'react';

export default function App() {
  return (
    <div className="App">
      <h1>Lucas Briesemeister</h1>
      <h2>Under construction</h2>
    </div>
  );
}
